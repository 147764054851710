import React, { useEffect } from "react"
import BaseModal from "helper/component/basemodal"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { formSubmit } from "redux/actions/main"
import { toggleModal } from "redux/actions/modal"

const Input = ({ label, dataKey, register, required, type = "text", placeholder }) => (
	<div>
		<label>{label}</label>
		{type === "tel" && <div className="country-code">+62</div>}
		<input {...register(dataKey, { required })} type={type} placeholder={placeholder} />
	</div>
)

const Form = () => {
	const dispatch = useDispatch()
	const { lang_json, login } = useSelector((state) => state.main)
	const { register, handleSubmit } = useForm()
	const onSubmit = (data) => dispatch(formSubmit(data))

	useEffect(() => {
		if (login.user?.filled_form === false) {
			// if (history?.find((e) => e.voucher_code || e.item_type === "Physical")) {
			dispatch(toggleModal({ name: "form", state: true }))
			// }
		}
	}, [dispatch, login])

	return (
		<BaseModal tag="form" title={false} category={lang_json.txt_popup_winner_form} closeIcon={false}>
			<div className="form-wrapper">
				<span className="title" dangerouslySetInnerHTML={{ __html: lang_json.txt_popup_form_description }} />

				<form onSubmit={handleSubmit(onSubmit)}>
					<div>
						<Input
							label={lang_json.txt_form_full_name}
							placeholder={lang_json.txt_form_full_name_placeholder}
							dataKey="full_name"
							register={register}
							required
						/>
						<Input
							label={lang_json.txt_form_id_card}
							placeholder={lang_json.txt_form_id_card_placeholder}
							dataKey="ic_number"
							type="phone_number"
							register={register}
							required
						/>
						<Input
							label={lang_json.txt_form_full_address}
							placeholder={lang_json.txt_form_full_address_placeholder}
							dataKey="address"
							register={register}
							required
						/>
						<div className="city">
							<Input
								label={lang_json.txt_form_city_province}
								placeholder={lang_json.txt_form_city_province_placeholder}
								dataKey="city_province"
								register={register}
								required
							/>
							<Input
								label={lang_json.txt_form_zipcode}
								placeholder={lang_json.txt_form_zipcode_placeholder}
								dataKey="postcode"
								type="number"
								register={register}
								required
							/>
						</div>
						<Input
							label={lang_json.txt_form_phone_num}
							placeholder={lang_json.txt_form_phone_num_placeholder}
							dataKey="phone_number"
							type="tel"
							register={register}
							required
						/>
					</div>
					<input className="btn btn-confirm" type="submit" value={lang_json.txt_send_form} />
				</form>
			</div>
		</BaseModal>
	)
}

export default Form
