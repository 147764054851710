import React from "react"
import Modal from "react-responsive-modal"
import { useDispatch, useSelector } from "react-redux"
import { toggleModal } from "redux/actions/modal"

function BaseModal({
	title,
	children,
	tag,
	size = "big",
	onExit = () => {},
	category,
	closeIcon = true,
	...props
}) {
	const dispatch = useDispatch()
	const onClose = () => {
		onExit()
		dispatch(toggleModal({ name: tag }))
	}
	const isOpen = useSelector((state) => state.modal.name[tag])
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			center={true}
			closeOnEsc={false}
			closeOnOverlayClick={false}
			blockScroll={false}
			classNames={{
				root: `root-${tag}`,
				modal: `modal modal-${tag} popup-${size}`,
				overlay: `modal__overlay overlay-${tag}`,
				closeButton: `btn-close close-${tag}`,
				// modalAnimationIn: ``,
				// modalAnimationOut: `${effect[tag]?.animOut || effect.prize.animOut}`,
				modalAnimationIn: `scaleY`,
				modalAnimationOut: `scaleYreverse`,
			}}
			// closeIcon={<div className="ico-close" />}
			showCloseIcon={closeIcon}
			focusTrapped={false}
			container={document.getElementById("Main")}
			{...props}
		>
			<span className="category">{category}</span>
			<div
				className={`modal__container ${tag}`}
				// style={{ animationDelay: 300}}
			>
				{title && (
					<div className={`modal__title ${tag}`}>
						<div className={`title-${tag}`}>{title}</div>
						<span className="subtitle">{props.subtitle}</span>
					</div>
				)}
				<div className={`modal__content ${tag}`}>{children}</div>
			</div>
		</Modal>
	)
}

export default BaseModal
