import React from "react"
import Confirmation from "./Confirmation"
import Consent from "./Consent"
import Error from "./Error"
import Form from "./Form"
import History from "./History"
import Puzzle from "./Puzzle"
import Rules from "./Rules"
import Share from "./Share"
import SharePuzzle from "./SharePuzzle"
import Success from "./Success"
import Voucher from "./Voucher"

const ModalCont = () => {
	return (
		<>
			<Success />
			<Confirmation />
			<Form />
			<Share />
			<Voucher />
			<Puzzle />
			<Error />
			<SharePuzzle />
			<History />
			<Rules />
			<Consent />
		</>
	)
}

export default ModalCont
