import React from "react"
import BaseModal from "helper/component/basemodal"
import { useDispatch, useSelector } from "react-redux"
import { toggleModal } from "redux/actions/modal"

const Puzzle = () => {
	const dispatch = useDispatch()
	const { lang_json, result } = useSelector((state) => state.main)

	return (
		<BaseModal
			tag="puzzle"
			title={result?.code ? lang_json.txt_puzzle_get_title : lang_json.txt_congrats_title}
			category={lang_json.txt_popup_system}
		>
			<div className="puzzle-wrapper">
				<div className="item">
					<div
						style={{
							backgroundImage: `url(${lang_json.puzzle_pieces?.[0][`img_puzzle_${result?.puzzle_id}`]})`,
						}}
					/>
				</div>
				<span className="puzzle-num">{lang_json.txt_puzzle?.replace("{num}", result?.puzzle_id)}</span>
				{result?.code && <span className="puzzle-code">{result.code}</span>}
				<span
					className="puzzle-desc"
					dangerouslySetInnerHTML={{
						__html: lang_json.txt_puzzle_get_description
							?.replace("{num}", result?.puzzle_id)
							.replace("\n", "<br/>"),
					}}
				/>
			</div>
			<button className="btn btn-confirm" onClick={() => dispatch(toggleModal({ name: "puzzle" }))}>
				{lang_json.txt_btn_confirm}
			</button>
		</BaseModal>
	)
}

export default Puzzle
