import axios from "axios"
import {
	getAllUrlParam,
	getLsObject,
	removeParamUrl,
	setLsObject,
	// fetchCss
} from "helper"
import { login, catch_error } from "./main"
import { toggleModal } from "./modal"
const ls = window.localStorage

export const set_param = (data) => ({
	type: "SET_PARAM",
	data,
})

export const set_token = () => {
	return (dispatch, getState) => {
		const { auth } = getState()
		const params = getAllUrlParam()
		let param = Object.keys(params).length === 0 ? auth?.param : params

		if (param?.access_token || param?.gop_access_token) {
			dispatch(getJWT(param))
				.then(() => {
					/** Call api checklogin or info */
					dispatch(login())
					dispatch(set_param(param))
					// dispatch(get_translate(ls.region))

					if (!getLsObject("not_first_time")) {
						dispatch(toggleModal({ name: "rules", state: true }))
						setLsObject("not_first_time", true)
					}
				})
				.catch((err) => {
					/** Handle error , show modal or toast error */
					dispatch(catch_error("not_logged_in"))
				})
		} else {
			/** Handle error , show modal or toast error */
			dispatch(catch_error("not_logged_in"))
			return
		}
	}
}

export const getJWT = (param) => {
	return () => {
		return axios.get(`oauth/callback/`, { params: param }).then((resp) => {
			removeParamUrl()
			ls.setItem("token", resp.data.token)
			ls.setItem("region", resp.data.region)
			axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = resp.data.token
		})
	}
}
