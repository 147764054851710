import React, { lazy, Suspense, useEffect } from "react";
import { Routes, BrowserRouter, Route, Navigate } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { useDispatch, useSelector } from "react-redux";
// Component

import ViewportRestrict from "helper/component/viewport";
import Loader from "helper/component/loader";
import LoadingScreen from "helper/component/loadingscreen";
import Fallback from "helper/component/fallback";
import { componentLoader, getLsObject } from "helper";
import { useCSSVariable } from "helper/hooks/useCSSVariable";
import Header from "component/Header";
import Navigation from "component/Navigation";
import ModalCont from "component/Modal";
import { set_token } from "redux/actions/auth";
import "animate.css";
import { get_translate, shareEvent, specialClaim } from "redux/actions/main";

// Pages
const DailyLogin = lazy(() =>
    componentLoader(() => import("pages/DailyLogin"))
);
const Puzzle = lazy(() => componentLoader(() => import("pages/Puzzle")));
const Spin = lazy(() => componentLoader(() => import("pages/Spin")));

export const Main = () => {
    const dispatch = useDispatch();
    const { loader, lang_json, on_spin, login } = useSelector(
        (state) => state?.main
    );
    const { name } = useSelector((state) => state?.modal);

    useEffect(() => {
        dispatch(set_token());
        dispatch(get_translate());
    }, [dispatch]);

    useEffect(() => {
        if (login && getLsObject("shared")) {
            dispatch(shareEvent());
        }
    }, [dispatch, login, name]);

    useCSSVariable({ list: lang_json });

    return (
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
            <div id="Main" className="main-panel">
                <ErrorBoundary fallback={Fallback}>
                    <Suspense fallback={<LoadingScreen />}>
                        <ViewportRestrict
                            display={true}
                            type="portrait"
                            text={lang_json.txt_viewport_restrict}
                        />
                        <Loader loader={loader} />
                        <div className={`content-container ${on_spin && "onspin"}`}>
                            <Header />

                            <div className="content-wrapper">
                                <Navigation />

                                <div className="main-content">
                                    {!login.user?.claimed_special && login.user?.is_new && (
                                        <div className="special-claim" onClick={() => dispatch(specialClaim())} />
                                    )}
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                lang_json?.toggle_daily_login ? (
                                                    <DailyLogin />
                                                ) : (
                                                    <Navigate
                                                        replace
                                                        to={"/puzzle" + window.location.search}
                                                    />
                                                )
                                            }
                                        />
                                        <Route path="/puzzle" element={<Puzzle />} />
                                        <Route path="/spin" element={<Spin />} />
                                        <Route
                                            path="*"
                                            element={<Navigate to={"/" + window.location.search} />}
                                        />
                                    </Routes>
                                </div>
                            </div>
                        </div>
                    </Suspense>
                    <ModalCont />
                </ErrorBoundary>
            </div>
        </BrowserRouter>
    );
};

export default Main;
