import React, { useEffect, useState } from "react"
import BaseModal from "helper/component/basemodal"
import { useSelector } from "react-redux"
import CopyToClipboard from "react-copy-to-clipboard"

const SharePuzzle = () => {
	const [copied, setCopied] = useState(false)
	const { lang_json, result } = useSelector((state) => state.main)

	useEffect(() => {
		copied &&
			setTimeout(() => {
				setCopied(false)
			}, 2000)
	}, [copied])

	return (
		<BaseModal
			tag="sharepuzzle"
			title={lang_json.txt_puzzle_share_title}
			category={lang_json.txt_popup_system}
		>
			<div className="puzzle-wrapper">
				<div className="item">
					<div
						style={{
							backgroundImage: `url(${lang_json.puzzle_pieces?.[0][`img_puzzle_${result?.puzzle_id}`]})`,
						}}
					/>
				</div>
				<span className="puzzle-num">{lang_json.txt_puzzle?.replace("{num}", result?.puzzle_id)}</span>
				<span className="puzzle-code">{result?.code || result?.share_code || result}</span>
				<span className="puzzle-desc">{lang_json.txt_puzzle_share_description}</span>
				{copied && <span className="success-copy">{lang_json.txt_share_event_success_copy}</span>}
			</div>
			<CopyToClipboard text={result?.share_code || result?.code || result} onCopy={() => setCopied(true)}>
				<button className="btn btn-confirm">{lang_json.txt_btn_copy}</button>
			</CopyToClipboard>
		</BaseModal>
	)
}

export default SharePuzzle
