import React, { useEffect } from "react"
import BaseModal from "helper/component/basemodal"
import { useDispatch, useSelector } from "react-redux"
import { getLsObject, getRegionByHostname, setLsObject } from "helper"
import { toggleModal } from "redux/actions/modal"

const Consent = () => {
	const dispatch = useDispatch()
	const { lang_json } = useSelector((state) => state.main)

	const closeModal = (agree) => {
		setLsObject("th_policy", agree)
		dispatch(toggleModal({ name: "consent" }))
	}

	useEffect(() => {
		if (getRegionByHostname() === "TH" && !getLsObject("th_policy")) {
			dispatch(toggleModal({ name: "consent", state: true }))
		}
	}, [dispatch])

	return (
		<BaseModal tag="consent">
			<div className="consent-wrapper">
				<div className="consent-title">
					<span>{lang_json.txt_consent_title}</span>
					<span>CODM</span>
					<span>{lang_json.txt_consent_event_name}</span>
				</div>
				<div className="text-wrapper">
					<div dangerouslySetInnerHTML={{ __html: lang_json.txt_consent_content }} />
				</div>
			</div>
			<div className="buttons">
				<button className="btn btn-back" onClick={() => closeModal(false)}>
					{lang_json.txt_consent_reject}
				</button>
				<button className="btn btn-open" onClick={() => closeModal(true)}>
					{lang_json.txt_consent_accept}
				</button>
			</div>
		</BaseModal>
	)
}

export default Consent
