import React, { useEffect, useState } from "react"
import BaseModal from "helper/component/basemodal"
import { useSelector } from "react-redux"
import CopyToClipboard from "react-copy-to-clipboard"

const Voucher = () => {
	const [copied, setCopied] = useState(false)
	const { lang_json, result } = useSelector((state) => state.main)

	useEffect(() => {
		copied &&
			setTimeout(() => {
				setCopied(false)
			}, 2000)
	}, [copied])

	return (
		<BaseModal tag="voucher" title={lang_json.txt_congrats_title} category={lang_json.txt_popup_system}>
			<div className="voucher-wrapper">
				<div className="item">
					<div style={{ backgroundImage: `url(${result?.img_url})` }} />
				</div>
				<span className="item-name">{result?.voucher_name}</span>
				<span className="code">{result?.voucher_code}</span>
				<span className="wording">{lang_json.txt_voucher_description}</span>
				{copied && <span className="success-copy">{lang_json.txt_share_event_success_copy}</span>}
			</div>
			<CopyToClipboard text={result?.voucher_code} onCopy={() => setCopied(true)}>
				<button className="btn btn-confirm">{lang_json.txt_btn_copy}</button>
			</CopyToClipboard>
		</BaseModal>
	)
}

export default Voucher
