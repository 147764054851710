import React from "react"
import BaseModal from "helper/component/basemodal"
import { useDispatch, useSelector } from "react-redux"
import { toggleModal } from "redux/actions/modal"
import { login } from "redux/actions/main"

const Success = () => {
	const dispatch = useDispatch()
	const { lang_json, result } = useSelector((state) => state.main)

	const checkGp = () => {
		window.localStorage.removeItem("result")
		if (result?.voucher_code || result?.item_type === "Physical") {
			dispatch(toggleModal({ name: "form", state: true }))
		}
		dispatch(login())
	}

	return (
		<BaseModal
			tag="success"
			title={lang_json.txt_congrats_title}
			category={lang_json.txt_popup_system}
			subtitle={
				result?.item_type === "Spin Token"
					? lang_json.txt_congrats_token_description?.replace("{token_name}", result.item_name)
					: lang_json.txt_congrats_item_description
			}
			onExit={() => checkGp()}
		>
			<div className="item-wrapper">
				{result?.length > 1 ? (
					result?.map?.((dt, idx) => (
						<div key={idx}>
							<div className={`item ${dt.rarity?.toLowerCase()}`}>
								<div style={{ backgroundImage: `url(${dt?.img_url})` }} />
							</div>
							<span>{dt?.item_name}</span>
						</div>
					))
				) : (
					<div>
						<div className={`item ${result?.rarity?.toLowerCase()}`}>
							<div style={{ backgroundImage: `url(${result?.img_url})` }} />
						</div>
						<span>{result?.item_name}</span>
					</div>
				)}
			</div>
			<button
				className="btn btn-confirm"
				onClick={() => dispatch(toggleModal({ name: "success" })) & checkGp()}
			>
				{lang_json.txt_btn_confirm}
			</button>
		</BaseModal>
	)
}

export default Success
