import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLsObject, setLsObject } from "helper";
import ReactHowler from "react-howler";
import { toggleModal } from "redux/actions/modal";

const Header = () => {
	const dispatch = useDispatch();
	const { lang_json } = useSelector((state) => state.main);
	const { user } = useSelector((state) => state.main.login);
	const [isMute, setIsMute] = useState(getLsObject("ismute") || false);

	const muteButton = () => {
		const muteLs = getLsObject("ismute");
		setIsMute(!isMute);
		setLsObject("ismute", !muteLs);
	};

	return (
		<div className="header">
			<div className="left-wrapper">
				<span
					style={{
						color: lang_json?.clr_scheme?.find(
							(f) => f.clr_scheme_field === "clr_txt_period"
						)?.clr_scheme,
					}}
					className="username"
				>
					{lang_json.txt_greeting?.replace(
						"{username}",
						user?.codm_nickname || "Guest"
					)}
				</span>
				<span
					style={{
						color: lang_json?.clr_scheme?.find(
							(f) => f.clr_scheme_field === "clr_txt_period"
						)?.clr_scheme,
					}}
					className="period"
				>
					{lang_json.txt_event_period}
				</span>
			</div>
			<div className="title">
				<div className="title-main" />
				<span
					style={{
						color: lang_json?.clr_scheme?.find(
							(f) => f.clr_scheme_field === "clr_txt_description"
						)?.clr_scheme,
					}}
					className="title-sub"
				>
					{lang_json.txt_subtitle}
				</span>
			</div>
			<div className="right-wrapper">
				<div className="buttons-wrapper">
					<button
						className="btn btn-help"
						onClick={() =>
							dispatch(toggleModal({ name: "rules", state: true }))
						}
					/>
					<button
						className={`btn btn-bgm ${isMute ? "off" : "on"}`}
						onClick={() => muteButton()}
					/>
					{lang_json.bgm && (
						<ReactHowler
							src={lang_json.bgm}
							playing={true}
							loop={true}
							mute={isMute}
						/>
					)}
				</div>
				<div className="tokens">
					<div className="token token-a">
						<span
							style={{
								color: lang_json?.clr_scheme?.find(
									(f) => f.clr_scheme_field === "clr_txt_balance"
								)?.clr_scheme,
							}}
						>
							{user?.puzzle_token}
						</span>
					</div>
					<div className="token token-b">
						<span
							style={{
								color: lang_json?.clr_scheme?.find(
									(f) => f.clr_scheme_field === "clr_txt_balance"
								)?.clr_scheme,
							}}
						>
							{user?.spin_token}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
