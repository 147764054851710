import React, { Component } from "react"
import PropTypes from "prop-types"
import "./style.scss"

class LoadingScreen extends Component {
	render() {
		return (
			<div
				className={`loading ${this.props.type}`}
				style={{
					backgroundColor: `${this.props.backgroundColor}`,
				}}
			>
				<div className="wp_content">
					<img
						src="https://cdngarenanow-a.akamaihd.net/gstaticid/CODM/scavangerjourney/loader.gif"
						alt=""
						className="img"
					/>
				</div>
			</div>
		)
	}
}

LoadingScreen.defaultProps = {
	text: "Loading...",
	backgroundColor: "rgba(0,0,0, 1)",
}

LoadingScreen.propTypes = {
	text: PropTypes.string,
	type: PropTypes.string,
	backgroundColor: function (props, propName, componentName) {
		if (props[propName].indexOf("rgb") < 0) {
			if (props[propName].indexOf("rgba") < 0) {
				if (props[propName].indexOf("#") < 0) {
					return new Error(
						"Invalid prop `" +
							propName +
							"` supplied to" +
							" `" +
							componentName +
							"`. Only accept RGB, RGBA or Hex code."
					)
				}
			}
		}
	},
}

export default LoadingScreen
