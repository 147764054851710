import React from "react"
import BaseModal from "helper/component/basemodal"
import { useDispatch, useSelector } from "react-redux"
import { format, fromUnixTime } from "date-fns"
import { zhTW, th, id } from "date-fns/locale"
import { toggleModal } from "redux/actions/modal"
import { put_data } from "redux/actions/main"
import { getRegionByHostname } from "helper"

const History = () => {
	const dispatch = useDispatch()
	const { history, lang_json } = useSelector((state) => state.main)

	const openVoucher = (voucher_code, voucher_name) => {
		dispatch(put_data("result", { voucher_code, voucher_name }))
		dispatch(toggleModal({ name: "voucher", state: true }))
	}

	const dateFormat = (date) => {
		switch (getRegionByHostname()) {
			case "TW":
				return format(fromUnixTime(date), "LLL do", { locale: zhTW })
			case "TH":
				return format(fromUnixTime(date), "dd LLLL", { locale: th })
			case "ID":
				return format(fromUnixTime(date), "dd LLLL", { locale: id })
			default:
				return format(fromUnixTime(date), "dd MMMM")
		}
	}

	return (
		<BaseModal tag="history" title={lang_json.txt_history_title} category={lang_json.txt_history_title}>
			<div className="history-wrapper">
				<div className="history-header">
					<span>{lang_json.txt_date}</span>
					<span>{lang_json.txt_reward}</span>
				</div>
				<div className="history-body">
					{history?.map((dt, idx) => (
						<div key={idx} className="list">
							<span>{dateFormat(dt.created_at)}</span>
							<span
								style={dt.voucher_code ? { textDecoration: "underline", fontWeight: "bold" } : {}}
								onClick={() => dt.voucher_code && openVoucher(dt.voucher_code, dt.voucher_name)}
							>
								{dt.voucher_code || dt.item_name}
							</span>
						</div>
					))}
				</div>
			</div>
		</BaseModal>
	)
}

export default History
