import React, { useEffect, useState } from "react"
import BaseModal from "helper/component/basemodal"
import { FacebookShareButton, LineShareButton, WhatsappShareButton } from "react-share"
import {  getRegionByHostname } from "helper"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useDispatch, useSelector } from "react-redux"
import { toggleModal } from "redux/actions/modal"
import { setLsObject } from "helper"
import { format } from "date-fns"

const Share = () => {
	const dispatch = useDispatch()
	const [copied, setCopied] = useState(false)
	const { lang_json, login } = useSelector((state) => state.main)
	const shareUrl = `https://share.garena.co.id/?collection=tr_codm_moonlight&region=${getRegionByHostname() }`

	useEffect(() => {
		copied &&
			setTimeout(() => {
				setCopied(false)
			}, 2000)
	}, [copied])

	const shareAction = () => {
		const lastshare = format(new Date(login?.user?.last_shared * 1000 || true), "d")
		const currentDate = format(new Date(), "d")

		if (lastshare !== currentDate) {
			setLsObject("shared", true)
		}
	}

	const shareButtonList = (socmed, key) => {
		switch (socmed) {
			case "fb":
				return (
					<FacebookShareButton key={key} beforeOnClick={() => shareAction()} url={shareUrl}>
						<div className="ico ico-fb" />
					</FacebookShareButton>
				)
			case "line":
				return (
					<LineShareButton key={key} beforeOnClick={() => shareAction()} url={shareUrl}>
						<div className="ico ico-line" />
					</LineShareButton>
				)
			case "wa":
				return (
					<WhatsappShareButton key={key} beforeOnClick={() => shareAction()} url={shareUrl}>
						<div className="ico ico-wa" />
					</WhatsappShareButton>
				)
			default:
				break
		}
	}

	return (
		<BaseModal tag="share" title={lang_json.txt_share_event_title} category={lang_json.txt_popup_system}>
			<div className="share-wrapper">
				<span>{lang_json.txt_share_event_description}</span>
				<div className="sharelist">
					{lang_json.socmed_choices?.map?.((dt, idx) => shareButtonList(dt, idx))}
					<CopyToClipboard onCopy={() => setCopied(true) & shareAction()} text={shareUrl}>
						<div className="ico ico-copy" />
					</CopyToClipboard>
				</div>
				{copied && <span className="copymsg">{lang_json.txt_share_event_success_copy}</span>}
			</div>
			<button
				className="btn btn-confirm"
				onClick={() => {
					dispatch(toggleModal({ name: "share" }))
				}}
			>
				{lang_json.txt_btn_close}
			</button>
		</BaseModal>
	)
}

export default Share
