import React, { useEffect, useState } from "react"
import BaseModal from "helper/component/basemodal"
import { useSelector } from "react-redux"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { getLsObject } from "helper"

const Rules = () => {
	const [tabActive, setTabActive] = useState("howto")
	const { lang_json } = useSelector((state) => state.main)
	const [firsttime, setFirstTime] = useState()

	const SampleNextArrow = (props) => {
		const { className, style, onClick } = props
		return <div className={className} style={{ ...style, display: "block" }} onClick={onClick} />
	}

	const SamplePrevArrow = (props) => {
		const { className, style, onClick } = props
		return <div className={className} style={{ ...style, display: "block" }} onClick={onClick} />
	}

	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		autoplay: false,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	}

	useEffect(() => {
		setFirstTime(!getLsObject("not_first_time"))
	}, [tabActive])

	return (
		<BaseModal tag="rules" title={false} category={lang_json.txt_popup_rules}>
			<div className="rules-wrapper">
				<div className="rules-tab">
					<div
						className={`tab--howto ${tabActive === "rules" ? "inactive" : "active"}`}
						onClick={() => setTabActive("howto")}
					>
						<span>{lang_json.txt_how_to_play}</span>
						{firsttime && <div className="new" />}
					</div>
					<div
						className={`tab--rules ${tabActive === "howto" ? "inactive" : "active"}`}
						onClick={() => setTabActive("rules")}
					>
						<span>{lang_json.txt_rules}</span>
					</div>
				</div>

				<div className="content-wrapper">
					{tabActive === "rules" ? (
						<div
							className="rules-content"
							dangerouslySetInnerHTML={{ __html: lang_json.txt_rules_content }}
						/>
					) : (
						<div className="howto-content">
							<Slider {...settings}>
								{lang_json.tutorial_list?.map((dt, idx) => (
									<img key={idx} src={dt.image} alt="" />
								))}
							</Slider>
						</div>
					)}
				</div>
			</div>
		</BaseModal>
	)
}

export default Rules
