import React, { useState } from "react"
import BaseModal from "helper/component/basemodal"
import { useDispatch, useSelector } from "react-redux"
import { postSpin } from "redux/actions/main"
import { getLsObject, setLsObject } from "helper"
import { toggleModal } from "redux/actions/modal"

const Confirmation = () => {
	const dispatch = useDispatch()
	const [checked, setChecked] = useState(getLsObject("dontshow") || false)
	const { lang_json } = useSelector((state) => state.main)

	const spin = () => {
		if (checked) {
			setLsObject("dontshow", checked)
		}

		dispatch(postSpin())
	}

	return (
		<BaseModal
			tag="confirmation"
			title={lang_json.txt_confirmation_title}
			category={lang_json.txt_popup_system}
		>
			<div className="confirmation-wrapper">
				<span>{lang_json.txt_confirmation_description?.replace("{num}", 1)}</span>
				<div className="checkbox">
					<input
						type="checkbox"
						name="checkbox"
						id="checkbox"
						onChange={(e) => setChecked(e.target.checked)}
					/>
					<label htmlFor="checkbox">{lang_json.txt_dont_show_again}</label>
				</div>
			</div>
			<div className="buttons">
				<button className="btn btn-back" onClick={() => dispatch(toggleModal({ name: "confirmation" }))}>
					{lang_json.txt_btn_close}
				</button>
				<button className="btn btn-open" onClick={() => spin()}>
					{lang_json.txt_open}
				</button>
			</div>
		</BaseModal>
	)
}

export default Confirmation
