import axios from "axios"
import { err_msg, getLsObject, getRegionByHostname } from "helper"
import { setMsg, toggleModal } from "./modal"
import { format } from "date-fns"

const cdn = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
	withCredentials: false,
})

const common = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/tr_common/`,
	withCredentials: false,
})

const rarity = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/glb_codm_rarity/`,
	withCredentials: false,
})

export const toggle_loader = (data) => ({
	type: "TOGGLE_LOADER",
	data: data,
})

export const put_data = (key, data) => ({
	type: "PUT_DATA",
	key,
	data,
})

/**
 *
 * Example of how to call API
 *
 */
export const login = () => {
	return (dispatch, getState) => {
		// UNCOMMENT & ADD DEFAULT LANG AS PARAM ex: 'EN'
		// dispatch(get_translate('EN'))

		axios
			.get("api/info/")
			.then((resp) => {
				dispatch(put_data("login", resp.data))
				dispatch(dailyLogin())
				dispatch(getPuzzle())
				dispatch(getSpin())
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
	}
}

export const dailyLogin = () => {
	return (dispatch) => {
		axios.get("api/login/").then((resp) => {
			dispatch(put_data("daily_login", resp.data.data))
		})
	}
}

export const postLogin = (day) => {
	return (dispatch) => {
		axios
			.post("api/login/", { day })
			.then((resp) => {
				dispatch(put_data("result", resp.data.data))
				dispatch(
					toggleModal({
						name: "success",
						state: true,
					})
				)
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
	}
}

export const grandprizeClaim = () => {
	return (dispatch) => {
		axios
			.post("api/login/claim/")
			.then((resp) => {
				dispatch(put_data("result", resp.data.data))
				dispatch(
					toggleModal({
						name: "success",
						state: true,
					})
				)
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
	}
}

export const specialClaim = () => {
	return (dispatch) => {
		axios
			.post("api/special/claim/")
			.then((resp) => {
				dispatch(put_data("result", resp.data.data))
				dispatch(
					toggleModal({
						name: "success",
						state: true,
					})
				)
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
	}
}

export const getPuzzle = () => {
	return (dispatch) => {
		axios.get("api/puzzle/").then((resp) => {
			dispatch(put_data("puzzle", resp.data.data))
		})
	}
}

export const openPuzzle = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.post("api/puzzle/")
			.then((resp) => {
				dispatch(put_data("result", resp.data.msg))
				dispatch(
					toggleModal({
						name: "puzzle",
						state: true,
					})
				)
				dispatch(login())
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const sharePuzzle = (puzzle_id) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.post("api/puzzle/share/", { puzzle_id })
			.then((resp) => {
				dispatch(put_data("result", resp.data.data))
				dispatch(
					toggleModal({
						name: "sharepuzzle",
						state: true,
					})
				)
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const redeeemPuzzle = (code) => {
	return (dispatch) => {
		axios
			.post("api/puzzle/redeem/", { code })
			.then((resp) => {
				dispatch(put_data("result", resp.data.data))
				dispatch(getPuzzle())
				dispatch(
					toggleModal({
						name: "puzzle",
						state: true,
					})
				)
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
	}
}

export const claimMilestone = (milestone) => {
	return (dispatch) => {
		axios
			.post("api/puzzle/claim/", { milestone })
			.then((resp) => {
				dispatch(put_data("result", resp.data.data))
				dispatch(
					toggleModal({
						name: "success",
						state: true,
					})
				)
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
	}
}

export const getSpin = () => {
	return (dispatch) => {
		axios.get("api/spin/").then((resp) => {
			dispatch(put_data("spin", resp.data.prizes))
		})
	}
}

export const postSpin = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true))
		dispatch(toggleModal({ name: "confirmation" }))

		axios
			.post("api/spin/")
			.then((resp) => {
				dispatch(put_data("result", resp.data.prize))
				dispatch(put_data("on_spin", true))

				setTimeout(() => {
					resp.data.prize.voucher_code
						? dispatch(toggleModal({ name: "voucher", state: true }))
						: dispatch(toggleModal({ name: "success", state: true }))
					dispatch(put_data("on_spin", false))
					dispatch(spinHistory())
				}, 2430)
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const spinHistory = () => {
	return (dispatch) => {
		axios.get("api/spin/history/").then((resp) => {
			dispatch(put_data("history", resp.data.history))
		})
	}
}

export const luckyWinner = () => {
	return (dispatch) => {
		axios.get("api/spin/lucky/").then((resp) => {
			dispatch(put_data("lucky", resp.data.data))
		})
	}
}

export const shareEvent = () => {
	return (dispatch, getState) => {
		const lastshare =
			getState().main.login && format(new Date(getState().main.login.user.last_shared * 1000 || true), "d")
		const currentDate = format(new Date(), "d")

		if (lastshare !== currentDate) {
			dispatch(toggle_loader(true))

			axios
				.post("api/share/")
				.then((resp) => {
					dispatch(put_data("result", resp.data.msg))
					dispatch(toggleModal({ name: "puzzle", state: true }))
					dispatch(login())
				})
				.catch((err) => {
					console.log(err)
				})
				.then(() => {
					dispatch(toggle_loader(false))
				})
		}

		window.localStorage.removeItem("shared")
	}
}

export const formSubmit = (data) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.post("api/spin/form/", data)
			.then((resp) => {
				dispatch(toggleModal({ name: "form" }))
				dispatch(login())
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

/**
 *
 * Example of how to implement multi language
 *
 */
export const get_translate = (default_lang = "ID") => {
	return (dispatch) => {
		let language = getRegionByHostname() || getLsObject("param")?.lang.toUpperCase() || default_lang
		document.documentElement.setAttribute("data-lang", language)

		const main_localize = cdn.get(`${language}.json?${Math.random() * 10000}`)
		const common_localize = common.get(`${language}.json?${Math.floor(Math.random() * 10000)}`)
		const rarity_box = rarity.get(`data.json?${Math.floor(Math.random() * 10000)}`)

		axios
			.all([main_localize, common_localize, rarity_box])
			.then((resp) => {
				const json = {
					...resp[0].data,
					...resp[1].data,
					...resp[2].data,
				}

				dispatch(put_data("lang_json", json))
			})
			.catch((err) => {})
	}
}

/**
 *
 * Example of how to catch error if not logged in
 *
 */
export const catch_error = (err_msg) => {
	return (dispatch) => {
		if (err_msg) {
			dispatch(setMsg(err_msg))
		}

		dispatch(
			toggleModal({
				name: "error",
				state: true,
			})
		)

		dispatch(toggle_loader(false))

		if (err_msg === "invalid_region") {
			localStorage.clear()
		}
	}
}
