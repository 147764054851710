import React from "react"
import BaseModal from "helper/component/basemodal"
import { useSelector } from "react-redux"

const Error = () => {
	const { msg } = useSelector((state) => state.modal)
	const { lang_json } = useSelector((state) => state.main)

	return (
		<BaseModal
			tag="error"
			category={lang_json.txt_popup_system}
			closeIcon={msg === "not_logged_in" ? false : true}
		>
			<div className="error-wrapper">
				<span>{lang_json?.[msg] || msg}</span>
			</div>
		</BaseModal>
	)
}

export default Error
