import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { format } from "date-fns"
import { useDispatch, useSelector } from "react-redux"
import { toggleModal } from "redux/actions/modal"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Navigation = () => {
	const dispatch = useDispatch()
	const { lang_json, login, puzzle, daily_login } = useSelector((state) => state.main)
	const [isNew, setIsNew] = useState({
		navLogin: false,
		navPuzzle: false,
		navSpin: false,
		navShare: true,
	})

	const navs = [
		lang_json.toggle_daily_login && {
			path: "",
			name: lang_json.txt_tab_1,
			isNew: isNew.navLogin,
		},
		{
			path: "puzzle",
			name: lang_json.txt_tab_2,
			isNew: isNew.navPuzzle,
		},
		{
			path: "spin",
			name: lang_json.txt_tab_3,
			isNew: isNew.navSpin,
		},
	]

	console.log(navs)

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		autoplay: true,
	}

	useEffect(() => {
		const checkLogin = () => {
			return daily_login?.map((dt) => dt.can_claim).find((e) => e)
		}

		const checkPuzzle = () => {
			const checkMilestone = puzzle?.milestone_info?.map(
				(dt) => puzzle.board_info?.progress >= dt.milestone && !dt.is_claimed
			)
			return checkMilestone?.find((e) => e) || login.user?.puzzle_token >= 1
		}

		const checkSpin = () => {
			return login.user?.spin_token >= 1
		}

		const checkShare = () => {
			const lastshareDate = login && format(new Date(login.user?.last_shared * 1000 || true), "d")
			const currentDate = format(new Date(), "d")

			return lastshareDate !== currentDate
		}

		setIsNew((isNew) => ({ ...isNew, navLogin: checkLogin() }))
		setIsNew((isNew) => ({ ...isNew, navPuzzle: checkPuzzle() }))
		setIsNew((isNew) => ({ ...isNew, navSpin: checkSpin() }))
		setIsNew((isNew) => ({ ...isNew, navShare: checkShare() }))
	}, [puzzle, login, daily_login])

	return (
		<div className="navigation">
			<div className="navigation-wrapper">
				{navs.map(
					(dt, idx) =>
						dt && (
							<NavLink exact="true" key={idx} to={dt.path} className={`btn btn-${dt.path}`}>
								{dt.isNew && <div className="new" />}
								{dt.name}
							</NavLink>
						)
				)}
			</div>

			<button
				className="btn btn-share"
				onClick={() =>
					dispatch(
						toggleModal({
							name: "share",
							state: true,
						})
					)
				}
			>
				{isNew.navShare && <div className="new" />}
			</button>

			<div className="sponsor-sliders">
				<Slider {...settings}>
					{lang_json.sponsor_list?.map((dt, idx) => (
						<img key={idx} src={dt.image} alt="" />
					))}
				</Slider>
			</div>
		</div>
	)
}

export default Navigation
